import { Box } from '@chakra-ui/react';
import React from 'react';
import { Appointment } from './components/Appointment/Appointment';
import { BookAnAppointmentV4Provider } from './contexts/BookAnAppointmentV4Context';
import { useBookAnAppointmentV4 } from './hooks/useBookAnAppointmentV4';
import { BackButton } from './components/BackButton';
import { PrivacyNotice } from './components/PrivacyNotice';
import { IBookAnAppointmentV4 } from './IBookAnAppointmentV4.interface';

const BookAnAppointmentV4: React.FC<any> = (props: IBookAnAppointmentV4) => {
  return (
    <BookAnAppointmentV4Provider cmsData={props}>
      <BookAnAppointmentV4Content {...props} />
    </BookAnAppointmentV4Provider>
  );
};

const BookAnAppointmentV4Content: React.FC<any> = (
  props: IBookAnAppointmentV4
) => {
  const { state } = useBookAnAppointmentV4();

  return (
    <Box display="flex" justifyContent="center" className="book-appointment-v4">
      <Box mx={[0, 0, 6, 8]} width={['100%', '100%', '100%', 570, 570]}>
        <Box m={6} display="flex" flexDirection="column" gap="16px">
          {((state.value as string).includes('Step 1') ||
            (state.value as string).includes('Step 2')) && <BackButton />}

          <Appointment />
          <PrivacyNotice />
        </Box>
      </Box>
    </Box>
  );
};

export { BookAnAppointmentV4 };
