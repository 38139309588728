import { isValidPhoneNumber } from 'react-phone-number-input/max';

const validateName = (value: string) => {
    const message = 'Please enter your full name';
    if (!value || value.trim() === '') {
      return message;
    }

    if (value) {
      const nameSplitted = value.split(' ');
      if (nameSplitted.length === 1) {
        return message;
      }
    }

    return true;
  };

  const validateEmail = (value: string) => {
    const message = 'Please enter a valid email address';
    const emailRegex = /^[a-z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!value || !emailRegex.test(value)) {
      return message;
    }
    return true;
  };

  const isPhoneValid = (phoneNumber: string) => {
    const message = 'Please enter a valid phone number';
    return isValidPhoneNumber(phoneNumber) ? undefined : message;
  };

  const validateBirthday = (value: string) => {
    const message = 'Please enter your date of birth in a DD/MM/YYYY format.';
    const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    const match = RegExp(dateRegex).exec(value);

    if (!match) {
      return message;
    }

    const day = parseInt(match[1], 10);
    const month = parseInt(match[2], 10);
    const year = parseInt(match[3], 10);

    const date = new Date(year, month - 1, day);
    if (
      date.getFullYear() !== year ||
      date.getMonth() + 1 !== month ||
      date.getDate() !== day
    ) {
      return message;
    }

    return true;
  };

  const checkIsInvalid = (field: any) => {
    if(field) {
      return true
    } else {
      return false;
    }
  }

  export { validateName, validateEmail, isPhoneValid, validateBirthday, checkIsInvalid }