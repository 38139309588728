import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import TopIcons from '../images/top-icons.png';
import { useBookAnAppointmentV4 } from '../hooks/useBookAnAppointmentV4';

const TopTitle = () => {
  const { state } = useBookAnAppointmentV4();

  return (
    <Box display="flex" paddingTop="4px" paddingBottom="4px" gap="8px">
      <Box display="flex" flexDirection="column" gap="8px" flex="1">
        <Text
          fontWeight="bold"
          fontSize="16px"
          textTransform="uppercase"
          color={'var(--Brand-700, #4D599E)'}
        >
          {state.context.cmsData.step_1__small_title}
        </Text>
        <Text
          fontWeight="normal"
          variant={['title-md', 'title-md', 'title-md', 'title-lg']}
          color={'var(--Base-400, #0C1E68)'}
        >
          {state.context.cmsData.step_1__title}
        </Text>
      </Box>

      <Box display="flex" alignItems="center">
        <Image src={TopIcons} style={{ width: 'auto', height: 'auto' }} />
      </Box>
    </Box>
  );
};

export { TopTitle };
