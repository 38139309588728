import { OnePlanFormType } from 'common/components/organisms/Calculator/utils/interfaces'
import { getOnePlanCovers } from 'common/components/organisms/Calculator/utils/onePlanConfig'
import { GetStartedProps } from './../../../common/GetStarted'
import { ValidityCardProps } from './../../../common/ValidityCard'
import numeral from 'numeral'

export const getBillCoverAmount = (formValues: OnePlanFormType) => {
    const combinedIncome = formValues.income2
        ? Number(formValues.income1) + Number(formValues.income2)
        : Number(formValues.income1)

    // Bill Cover to max of 40% of combined income
    const billCoverAmountMax = combinedIncome * 0.4
    const billCoverAmountTotal = formValues.mortgagePayment
        ? Math.min(billCoverAmountMax, Number(formValues.mortgagePayment))
        : billCoverAmountMax

    // Bill Cover to be split between partners in the event of a joint policy
    const billCoverAmtBeforeUtilities =
        formValues.jointLife === 'Y'
            ? billCoverAmountTotal / 2
            : billCoverAmountTotal
    const billCoverAmount = billCoverAmtBeforeUtilities + 400
    return billCoverAmount
}
const findIfApplicable = (
    FormValues: OnePlanFormType,
    cover1: any,
    cover2: any
) => {
    if (FormValues.jointLife === 'Y') {
        return cover1 !== 0 || cover2 !== 0
    } else {
        return cover1 !== 0
    }
}
const ifBillCoverApplicable = (FormValues: OnePlanFormType) => {
    if (FormValues.jointLife === 'Y') {
        return (
            FormValues.isAddtionalProtectionBillCover !== 'Y' ||
            FormValues.isAddtionalProtectionBillCover2 !== 'Y'
        )
    } else {
        return FormValues.isAddtionalProtectionBillCover !== 'Y'
    }
}
const addValues = (val?: string, val2?: string) => {
    let first = val ? parseFloat(val) : 0,
        second = val2 ? parseFloat(val2) : 0
    let result = first + second
    return result.toFixed(2)
}
export const getOnePlanColumns = (state: any) => {
    const formValues = state.context.formValues as OnePlanFormType
    const quoteData = state.context.quoteData
    const covers = getOnePlanCovers(formValues)

    const billCoverAmount = numeral(getBillCoverAmount(formValues)).format(
        '0,000'
    )
    let funeralCover
    if (formValues.isMarried === 'N' && formValues.childNum < 1) {
        funeralCover = 'Y'
    }
    return {
        columns: [
            {
                title: 'Standard',
                modalContent: getModelContent(quoteData.standardQuoteData),
                price: `€${Number(
                    quoteData?.standardQuoteData?.premInclLevy
                ).toFixed(2)}`,

                data: {
                    heading: 'Standard',
                    items: [
                        {
                            isApplicable: findIfApplicable(
                                formValues,
                                covers.decreasingLifeCover1?.StandardPlan,
                                covers.decreasingLifeCover2?.StandardPlan
                            ),
                            title: 'Decreasing life cover',
                            yourCover: covers.decreasingLifeCover1?.StandardPlan
                                ? `€${numeral(
                                      covers.decreasingLifeCover1?.StandardPlan
                                  ).format('0,000')}`
                                : 'Not applicable',
                            partnerCover:
                                covers.decreasingLifeCover2?.StandardPlan !== 0
                                    ? `€${numeral(
                                          covers.decreasingLifeCover2
                                              ?.StandardPlan
                                      ).format('0,000')} \n
                                  (50% of the gap in life cover)`
                                    : 'Not applicable',
                        },
                        {
                            isApplicable: ifBillCoverApplicable(formValues),
                            title: 'Bill cover',
                            yourCover:
                                formValues.isAddtionalProtectionBillCover ===
                                'Y'
                                    ? 'Not Applicable'
                                    : `€${billCoverAmount} per month up to ${covers.billCover1?.StandardPlan} years`,
                            partnerCover:
                                formValues.isAddtionalProtectionBillCover2 ===
                                'Y'
                                    ? 'Not Applicable'
                                    : `€${billCoverAmount} per month up to ${covers.billCover2?.StandardPlan} years`,
                        },
                        {
                            isApplicable: findIfApplicable(
                                formValues,
                                covers.specifiedIllness1?.StandardPlan,
                                covers.specifiedIllness2?.StandardPlan
                            ),
                            title: 'Specified illness cover',
                            yourCover:
                                covers.specifiedIllness1?.StandardPlan !== 0
                                    ? `€${numeral(
                                          covers.specifiedIllness1.StandardPlan
                                      ).format('0,000')}`
                                    : 'Not applicable',
                            partnerCover:
                                covers.specifiedIllness2?.StandardPlan !== 0
                                    ? `€${numeral(
                                          covers.specifiedIllness2?.StandardPlan
                                      ).format(
                                          '0,000'
                                      )} \n (Six months net salary)`
                                    : 'Not applicable',
                        },
                        {
                            isApplicable: funeralCover === 'Y',
                            title: 'Funeral cover',
                            yourCover: '€10,000',
                            partnerCover: '€10,000',
                        },
                    ],
                },
            },
            {
                title: 'Essential',
                modalContent: getModelContent(quoteData.essentialQuoteData),
                price: `€${Number(
                    quoteData?.essentialQuoteData?.premInclLevy
                ).toFixed(2)}`,
                data: {
                    heading: 'Essential',
                    items: [
                        {
                            isApplicable: findIfApplicable(
                                formValues,
                                covers.decreasingLifeCover1?.EssentialPlan,
                                covers.decreasingLifeCover2?.EssentialPlan
                            ),
                            title: 'Decreasing life cover',
                            yourCover:
                                covers.decreasingLifeCover1?.EssentialPlan !== 0
                                    ? `€${numeral(
                                          covers.decreasingLifeCover1
                                              ?.EssentialPlan
                                      ).format('0,000')}`
                                    : 'Not applicable',
                            partnerCover:
                                covers.decreasingLifeCover2?.EssentialPlan !== 0
                                    ? `€${numeral(
                                          covers.decreasingLifeCover2
                                              ?.EssentialPlan
                                      ).format(
                                          '0,000'
                                      )} \n (75% of the gap in life cover)`
                                    : 'Not applicable',
                        },
                        {
                            isApplicable: ifBillCoverApplicable(formValues),
                            title: 'Bill cover',
                            yourCover:
                                formValues.isAddtionalProtectionBillCover ===
                                'Y'
                                    ? 'Not aplicable'
                                    : `€${billCoverAmount} per month up to ${covers.billCover1?.EssentialPlan} years`,
                            partnerCover:
                                formValues.isAddtionalProtectionBillCover2 ===
                                'Y'
                                    ? 'Not Applicable'
                                    : `€${billCoverAmount} per month up to ${covers.billCover2?.EssentialPlan} years`,
                        },
                        {
                            isApplicable: findIfApplicable(
                                formValues,
                                covers.specifiedIllness1?.EssentialPlan,
                                covers.specifiedIllness2?.EssentialPlan
                            ),
                            title: 'Specified illness cover',
                            yourCover:
                                covers.specifiedIllness1?.EssentialPlan !== 0
                                    ? `€${numeral(
                                          covers.specifiedIllness1.EssentialPlan
                                      ).format('0,000')}`
                                    : 'Not applicable',
                            partnerCover:
                                covers.specifiedIllness2?.EssentialPlan !== 0
                                    ? `€${numeral(
                                          covers.specifiedIllness2
                                              ?.EssentialPlan
                                      ).format(
                                          '0,000'
                                      )} \n (One year net salary)`
                                    : 'Not applicable',
                        },
                        {
                            isApplicable: funeralCover === 'Y',
                            title: 'Funeral cover',
                            yourCover: '€10,000',
                            partnerCover: '€10,000',
                        },
                    ],
                },
            },
            {
                title: 'Comprehensive',
                modalContent: getModelContent(quoteData.comprehensiveQuoteData),
                price: `€${Number(
                    quoteData?.comprehensiveQuoteData?.premInclLevy
                ).toFixed(2)}`,
                data: {
                    heading: 'Comprehensive',
                    items: [
                        {
                            isApplicable: findIfApplicable(
                                formValues,
                                covers.decreasingLifeCover1?.ComprehensivePlan,
                                covers.decreasingLifeCover2?.ComprehensivePlan
                            ),
                            title: 'Decreasing life cover',
                            yourCover:
                                covers.decreasingLifeCover2
                                    ?.ComprehensivePlan !== 0
                                    ? `€${numeral(
                                          covers.decreasingLifeCover1
                                              ?.ComprehensivePlan
                                      ).format('0,000')}`
                                    : 'Not applicable',
                            partnerCover:
                                covers.decreasingLifeCover2
                                    ?.ComprehensivePlan !== 0
                                    ? `€${numeral(
                                          covers.decreasingLifeCover2
                                              ?.ComprehensivePlan
                                      ).format(
                                          '0,000'
                                      )} \n (75% of the gap in life cover)`
                                    : 'Not applicable',
                        },
                        {
                            isApplicable: ifBillCoverApplicable(formValues),
                            title: 'Bill cover',
                            yourCover:
                                formValues.isAddtionalProtectionBillCover ===
                                'Y'
                                    ? 'Not applicable'
                                    : `€${billCoverAmount} per month up to ${covers.billCover1?.ComprehensivePlan} years`,
                            partnerCover:
                                formValues.isAddtionalProtectionBillCover2 ===
                                'Y'
                                    ? 'Not Applicable'
                                    : `€${billCoverAmount} per month up to ${covers.billCover2?.ComprehensivePlan} years`,
                        },
                        {
                            isApplicable: findIfApplicable(
                                formValues,
                                covers.specifiedIllness1?.ComprehensivePlan,
                                covers.specifiedIllness2?.ComprehensivePlan
                            ),
                            title: 'Specified illness cover',
                            yourCover:
                                covers.specifiedIllness1?.ComprehensivePlan !==
                                0
                                    ? `€${numeral(
                                          covers.specifiedIllness1
                                              .ComprehensivePlan
                                      ).format('0,000')}`
                                    : 'Not applicable',
                            partnerCover:
                                covers.specifiedIllness2?.ComprehensivePlan !==
                                0
                                    ? `€${numeral(
                                          covers.specifiedIllness2
                                              ?.ComprehensivePlan
                                      ).format(
                                          '0,000'
                                      )} \n (Two year net salary)`
                                    : 'Not applicable',
                        },
                        {
                            isApplicable: funeralCover === 'Y',
                            title: 'Funeral cover',
                            yourCover: '€10,000',
                            partnerCover: '€10,000',
                        },
                    ],
                },
            },
        ],
    }
}

const getModelContent = (data: any) => {
    return `<h6>Decreasing Life Cover</h6>
<p>Pays a lump sum if anyone covered on the plan dies. The cover will decrease each year because the amount of replacement income your family needs reduces over time.</p>
<p><br></p>
<h6>Bill Cover</h6>
<p>Pays your essential monthly bills if the person covered cannot work because of an illness or injury while employed. You will not be able to claim if you become unemployed for more than one month and your claim may be limited if living abroad at the time of your claim.</p>
<p><br></p>
<h6>Specified Illness Cover</h6>
<p>Pays a lump sum if you are diagnosed with one of the illness covered on the plan.</p>
<p><br></p>
<h6>Funeral Cover</h6>
<p>A lump sum payment to help your family cover the cost of your funeral and other related expenses.</p>
<p><br></p>
<h6>Price Breakdown (per month)</h6>
<p>Cost of Decreasing Life Cover: €${addValues(
        data?.lifeCoverPrem1,
        data?.lifeCoverPrem2
    )} </br>
Cost of Bill Cover: €${addValues(data?.billPayPrem1, data?.billPayPrem2)} </br>
Cost of Illness Cover: €${addValues(
        data?.sicCoverPrem1,
        data?.sicCoverPrem2
    )}</br>
Irish Life Policy Fee: €${Number(data?.fee).toFixed(2)}</br>
1% Government Levy: €${Number(data?.levy).toFixed(2)}</br>
<strong>Total: €${Number(data?.premInclLevy).toFixed(2)}</strong></p>
<p><br></p>`
}

export const getStarted: GetStartedProps = {
    title: 'Next Steps',
    subtitle:
        'Speak to an advisor to help get started or for help with your quote.',
    phoneNumber: '(01) 704 1979',
}
export const benefits = [
    `98.6% of death claims paid (2023)`,
    `Supporting 8,000+ families with claims (2023)`,
    `80 years looking after the needs of people in Ireland (2023)`,
]
export const benefitsTitle = `Why choose Irish Life Assurance?`
export const validity: ValidityCardProps = {
    title: 'Validity',
    description: `This quote is valid for 7 days or until your next birthday, whichever is sooner and is subject to no changes to the applicable regulatory or tax rules within that period. You must keep up payments to stay on cover. This planner gives general guidance, but is not individual financial advice. We recommend you discuss your full circumstances and the cover with our of our qualified financial advisors.`,
}
export const RiskCard: ValidityCardProps = {
    title: 'Risk associated with this quote',
    description: `See cover requirements section below for further detail on the calculation and assumptions for recommended cover levels.
This assessment may result in an increase in the cost of cover. Irish Life Assurance plc is regulated by the Central Bank of Ireland. This indicative quote from Irish Life Financial Services is for a OnePlan protection policy from Irish Life Assurance plc and is subject to further assessment of health status.`,
}
export const coverRequirements = {
    accordion_section__title: 'Cover Requirements',
    accordion_section__items: [
        {
            id: 1,
            heading: 'Decreasing life cover',
            children_test: `<p>
                        <strong> Lump sum paid on death -</strong> The gap in
                        cover is calculated by taking your net income, minus
                        state benefits and any mortgage payments covered by
                        mortgage protection multiplied by the term. If you have
                        a mortgage, we assume that you have mortgage protection
                        in place. Protection Term is calculated on your youngest
                        child reaching age 25 or the remaining term of your
                        mortgage. State widows benefit of €1,029 plus an
                        additional €199 for each child per month is assumed. The
                        level of state benefit is dependent on the number of
                        PRSI contributions-we assume 48 or more in this
                        instance.
                    </p>`,
        },
        {
            id: 2,
            heading: 'Bill cover',
            children_test: `<p>Pays regular amount if unable to work due to injury or
                        illness - The recommended cover is calculated by adding
                        your monthly mortgage or rent amount, plus €400 for
                        essential bills such as gas, electricity etc. The amount
                        of cover is capped at €2,000 or 40% of monthly net
                        income, whichever is lower. Term is based on your
                        youngest child reaching 25 or the remaining of your
                        mortgage term.</p>`,
        },
        {
            id: 3,
            heading: 'Specified illness cover',
            children_test: `<p>The recommended cover is calculated at two year's net
                        salary. Term is based on your youngest child reaching age
                        25 or a term of 20 years if no children under the age of
                        25. If you are over 65 years of age, you are not
                        eligible for this benefit.</p>`,
        },
    ],
}
