import React from 'react';
import { Button } from '@irishlifedigitalhub/ds.atoms.button';
import { Box, useDisclosure } from '@chakra-ui/react';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import { Input } from '@irishlifedigitalhub/ds.molecules.input';
import { ArrowRightCircleIcon } from '@irishlifedigitalhub/ds.icons';
import { useBookAnAppointmentV4 } from 'common/components/organisms/BookAnAppointmentV4/hooks/useBookAnAppointmentV4';
import { Controller, useForm } from 'react-hook-form';
import { PhoneNumberInput } from './PhoneNumberInput';
import { Choice } from '@irishlifedigitalhub/ds.molecules.choice';
import { ModalBookAppointmentV4 } from '../../../ModalBookAppointmentV4';
import {
  validateEmail,
  isPhoneValid,
  validateBirthday,
  validateName,
  checkIsInvalid,
} from './Validations';
import { applyDateMask } from './Masks';

interface FormData {
  HasPartner: 'individual' | 'with_partner';
  Name: string;
  EmailAddress: string;
  MobilePhone: string;
  DateOfBirth: string;
  Name2ndLife: string;
  EmailAddress2ndLife: string;
  MobilePhone2ndLife: string;
  DateOfBirth2ndLife: string;
}

const Form = () => {
  const { state, send } = useBookAnAppointmentV4();
  const {
    isOpen: isOpenViewMoreInformationModal,
    onOpen: onOpenViewMoreInformationModal,
    onClose: onCloseViewMoreInformationModal,
  } = useDisclosure();
  const finalRefViewMoreInformationModal = React.useRef(null);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      HasPartner: 'individual',
      Name: '',
      EmailAddress: '',
      MobilePhone: '',
      DateOfBirth: '',
      Name2ndLife: '',
      EmailAddress2ndLife: '',
      MobilePhone2ndLife: '',
      DateOfBirth2ndLife: '',
    },
  });

  const onSubmit = (data: any) => {
    send({
      type: 'next',
      ...data,
    });
  };

  const handleFormSubmit = handleSubmit(onSubmit);

  return (
    <>
      {/* Type of review */}
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'8px'}
        className="type-of-review"
      >
        {/* Title */}
        <Box display={'flex'} flexDirection={'column'} gap={'4px'}>
          <Text
            color={'#0C1E68'}
            fontSize={'24px'}
            fontWeight={'medium'}
            lineHeight={'28.8px'}
          >
            What type of consultation would you like to do?
          </Text>
          <Text
            color={'#424FCF'}
            fontSize={'16px'}
            fontWeight={'medium'}
            cursor={'pointer'}
            textDecoration={'underline'}
            marginLeft={'3px'}
            onClick={() => onOpenViewMoreInformationModal()}
          >
            View more information
          </Text>
          <ModalBookAppointmentV4
            title={
              state.context.cmsData.step_2__view_more_information_modal___title
            }
            text={
              state.context.cmsData.step_2__view_more_information_modal___text
            }
            isOpen={isOpenViewMoreInformationModal}
            finalRef={finalRefViewMoreInformationModal}
            onClose={onCloseViewMoreInformationModal}
            onOpen={onOpenViewMoreInformationModal}
          />
        </Box>
        {/* Binary choice */}
        <Box display={'flex'} gap={'16px'} className="binary-choice">
          <Controller
            render={(field) => {
              return (
                <Choice
                  defaultSelected={[watch('HasPartner')]}
                  isBinary
                  size="lg"
                  columns={2}
                  onChange={(value) => {
                    let newValue = value;
                    if (!value) {
                      newValue = state.context.formData.HasPartner;
                    }
                    field.onChange(newValue);
                  }}
                  options={[
                    {
                      id: 'individual',
                      label: 'Individual',
                      value: 'individual',
                    },
                    {
                      id: 'with_partner',
                      label: 'With partner',
                      value: 'with_partner',
                    },
                  ]}
                />
              );
            }}
            name="HasPartner"
            control={control}
          />
        </Box>
      </Box>

      <Box
        display={'flex'}
        flexDirection="column"
        gap="12px"
        className="individual-details"
      >
        <Text
          color={'#0C1E68'}
          fontSize={'24px'}
          fontWeight={'medium'}
          lineHeight={'28.8px'}
        >
          Your information
        </Text>

        <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
          <Controller
            name="Name"
            control={control}
            rules={{ validate: validateName }}
            render={(field) => (
              <Input
                {...field}
                label="Name"
                isInvalid={checkIsInvalid(errors.Name)}
                helperText={errors.Name?.message}
              />
            )}
          />

          <Controller
            name="EmailAddress"
            control={control}
            rules={{ validate: validateEmail }}
            render={(field) => (
              <Input
                {...field}
                label="Email"
                isInvalid={checkIsInvalid(errors.EmailAddress)}
                helperText={
                  errors.EmailAddress?.message
                    ? errors.EmailAddress.message
                    : 'We will send you details of your appointment'
                }
              />
            )}
          />

          <Controller
            name="MobilePhone"
            control={control}
            rules={{
              required: 'Phone number is required',
              minLength: {
                value: 10,
                message: 'Phone number is required',
              },
              validate: {
                isPhoneValid,
              },
            }}
            render={(field) => {
              let newField: any = field;
              if (newField.ref) {
                delete newField.ref;
              }

              return (
                <PhoneNumberInput
                  {...newField}
                  control={control}
                  label="Mobile number"
                  isUncontrolled={false}
                  isInvalid={checkIsInvalid(errors.MobilePhone)}
                  helperText={
                    errors.MobilePhone?.message
                      ? errors.MobilePhone.message
                      : 'We will only call you about your appointment'
                  }
                />
              );
            }}
          />

          <Controller
            name="DateOfBirth"
            control={control}
            rules={{ validate: validateBirthday }}
            render={(field) => (
              <Input
                {...field}
                label="Date of birth"
                isInvalid={checkIsInvalid(errors.DateOfBirth)}
                helperText={
                  errors.DateOfBirth?.message
                    ? errors.DateOfBirth.message
                    : 'DD/MM/YYYY. This helps make the right recommendations'
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const maskedValue = applyDateMask(e.target.value);
                  field.onChange(maskedValue);
                }}
              />
            )}
          />
        </Box>
      </Box>

      {watch('HasPartner') === 'with_partner' && (
        <Box
          display={'flex'}
          flexDirection="column"
          gap="12px"
          className="partner-details"
        >
          <Text
            color={'#0C1E68'}
            fontSize={'24px'}
            fontWeight={'medium'}
            lineHeight={'28.8px'}
          >
            Your partner’s details
          </Text>

          <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
            <Controller
              name="Name2ndLife"
              control={control}
              rules={{ validate: validateName }}
              render={(field) => (
                <Input
                  {...field}
                  label="Name"
                  isInvalid={checkIsInvalid(errors.Name2ndLife)}
                  helperText={errors.Name2ndLife?.message}
                />
              )}
            />

            <Controller
              name="EmailAddress2ndLife"
              control={control}
              rules={{ validate: validateEmail }}
              render={(field) => (
                <Input
                  {...field}
                  label="Email"
                  isInvalid={checkIsInvalid(errors.EmailAddress2ndLife)}
                  helperText={
                    errors.EmailAddress2ndLife?.message
                      ? errors.EmailAddress2ndLife.message
                      : 'We will send details to your partner'
                  }
                />
              )}
            />

            <Controller
              name="MobilePhone2ndLife"
              control={control}
              rules={{
                required: 'Phone number is required',
                minLength: {
                  value: 10,
                  message: 'Phone number is required',
                },
                validate: {
                  isPhoneValid,
                },
              }}
              render={(field) => (
                <PhoneNumberInput
                  {...field}
                  control={control}
                  label="Mobile number"
                  isUncontrolled={false}
                  isInvalid={checkIsInvalid(errors.MobilePhone2ndLife)}
                  helperText={
                    errors.MobilePhone2ndLife?.message
                      ? errors.MobilePhone2ndLife.message
                      : 'We will only call about your appointment'
                  }
                />
              )}
            />

            <Controller
              name="DateOfBirth2ndLife"
              control={control}
              rules={{ validate: validateBirthday }}
              render={(field) => (
                <Input
                  {...field}
                  label="Date of birth"
                  isInvalid={checkIsInvalid(errors.DateOfBirth2ndLife)}
                  helperText={
                    errors.DateOfBirth2ndLife?.message
                      ? errors.DateOfBirth2ndLife.message
                      : 'DD/MM/YYYY. This helps make the right recommendations'
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const maskedValue = applyDateMask(e.target.value);
                    field.onChange(maskedValue);
                  }}
                />
              )}
            />
          </Box>
        </Box>
      )}

      {/* TO DO - Commented waiting for endpoint to be ready to receive this information */}
      {/* 
          <Divider
            orientation="horizontal"
            borderColor="#D8D6DF"
            borderWidth="1.5px"
          />
          <FormControl data-testid="Input">
            <FormLabel color={'monochrome.800'}>
              Add a message for your advisor (Optional)
            </FormLabel>
            <InputGroup>
              <StyledTextArea placeholder="What you would like to talk about in your meeting..."></StyledTextArea>
            </InputGroup>
            <StyledFormHelperText>
              300/300 characters remaining
            </StyledFormHelperText>
          </FormControl> */}

      {/* CTAs Mobile */}
      <Box display="flex" flexDirection="column" marginTop="10px" gap="16px">
        <Button
          type="submit"
          size="md"
          backgroundColor="#5E6CDA"
          color="white"
          fontWeight="600"
          rightIcon={<ArrowRightCircleIcon w="28px" h="28px" />}
          onClick={handleFormSubmit}
        >
          Confirm booking
        </Button>
        <Button
          size="md"
          variant="outline"
          onClick={() => {
            send({
              type: 'back',
            });
          }}
        >
          Previous
        </Button>
      </Box>
    </>
  );
};

export { Form };
