import { Box, ComponentWithAs, IconProps } from '@chakra-ui/react';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import React from 'react';

interface ITitleAndTextWithIcon {
  title?: string;
  text: string | JSX.Element;
  Icon: ComponentWithAs<'svg', IconProps>;
}

const TitleAndTextWithIcon = ({ title, text, Icon }: ITitleAndTextWithIcon) => {
  return (
    <Box display={'flex'} gap={'12px'} alignItems={title ? 'normal' : 'center'}>
      <Icon
        color={'#717FE2'}
        width={'18px'}
        height={'18px'}
        style={{ marginTop: title ? '5px' : '0px' }}
      />
      <Box display="flex" flexDirection="column">
        {title && (
          <Text color={'#0C1E68'} fontSize={'16px'} fontWeight={'semibold'}>
            {title}
          </Text>
        )}
        <Text color={'#4E4670'} fontSize={'16px'} fontWeight={'medium'}>
          {text}
        </Text>
      </Box>
    </Box>
  );
};

export { TitleAndTextWithIcon };
