import { Box } from '@chakra-ui/react';
import React from 'react';
import { ReactComponent as LeftArrowIcon } from '../images/left-arrow.svg';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import { navigate } from '@reach/router';
import { useBookAnAppointmentV4 } from '../hooks/useBookAnAppointmentV4';

const BackButton = () => {
  const { state, send } = useBookAnAppointmentV4();

  return (
    <Box
      display="flex"
      gap="8px"
      alignItems="center"
      cursor="pointer"
      onClick={() =>
        (state.value as string).includes('Step 1')
          ? navigate('/')
          : send({
              type: 'back',
            })
      }
    >
      <LeftArrowIcon />
      <Text fontWeight="semibold" fontSize="18px" color={'#5463D6'}>
        Back
      </Text>
    </Box>
  );
};

export { BackButton };
