import React from 'react';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import { Box } from '@chakra-ui/react';
import { CalendarIcon } from '@irishlifedigitalhub/ds.icons';
import { Button } from '@irishlifedigitalhub/ds.atoms.button';
import { Calendar } from '@irishlifedigitalhub/ds.organisms.calendar';
import { useBookAnAppointmentV4 } from 'common/components/organisms/BookAnAppointmentV4/hooks/useBookAnAppointmentV4';
import { Choice } from '@irishlifedigitalhub/ds.molecules.choice';
import { format, setHours, setMinutes } from 'date-fns';
import { Alert } from '@irishlifedigitalhub/ds.molecules.alert';
import { BoxWhiteRounded } from '../../../BoxWhiteRounded';
import { TopTitle } from '../../../TopTitle';
import { NeedMoreInformation } from '../../../NeedMoreInformation';
import { PleaseNote } from '../../../PleaseNote';
import { CantFindSuitableTime } from '../../../CantFindSuitableTime';
import { Spinner } from '@irishlifedigitalhub/ds.atoms.spinner';
import { fireAnalyticsEvent } from 'common/utils/fireAnalyticsEvent';

const Step1 = () => {
  const { state } = useBookAnAppointmentV4();

  return (
    <>
      <TopTitle />
      <BoxWhiteRounded className="step-1" enableHotStepper>
        {state.value !== 'Step 1 - Calendar Opened' && <Step1Times />}

        {state.value === 'Step 1 - Calendar Opened' && <Step1Calendar />}
      </BoxWhiteRounded>

      {!state.context.loading &&
        state.context.availableTimeslots.length > 0 && <NeedMoreInformation />}

      {!state.context.loading &&
        state.context.availableTimeslots.length === 0 && (
          <CantFindSuitableTime />
        )}

      {(state.value === 'Step 1' ||
        state.value === 'Step 1 - After Date Changed') && <PleaseNote />}
    </>
  );
};

const Step1Times = () => {
  const { state, send } = useBookAnAppointmentV4();
  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <Box display="flex" flexDirection="column" gap="8px">
        <Text fontWeight="medium" fontSize="24px" color="#0C1E68">
          {state.context.cmsData.step_1__title___time_section}
        </Text>
        <Text fontWeight="normal" fontSize="16px" color="#4E4670">
          Showing times for{' '}
          <strong>
            {state.context.currentDate.toLocaleDateString(undefined, {
              weekday: 'long',
              day: '2-digit',
              month: 'long',
              year: 'numeric',
            })}
          </strong>
        </Text>
        <Box display="flex">
          <Button
            variant="outline"
            minWidth="0"
            width="auto"
            minHeight="0"
            height="32px"
            fontSize="16px"
            paddingLeft="10px"
            paddingRight="10px"
            gap="8px"
            onClick={() => {
              fireAnalyticsEvent({
                name: 'IL_book_appointment_v4',
                properties: {
                  step: 'step_1',
                  info: 'Clicked on the Change Day button',
                },
              });
              send({
                type: 'Open Calendar',
              });
            }}
          >
            <CalendarIcon /> Change day
          </Button>
        </Box>
      </Box>

      {state.context.loading ? (
        <Box display="flex" justifyContent="center">
          <Spinner />
        </Box>
      ) : (
        <Box display="flex" gap="12px" flexWrap="wrap">
          {state.context.availableTimeslots.length > 0 ? (
            <Choice
              columns={2}
              isBinary
              onChange={(value) => {
                const timeslot: [string, string] | undefined =
                  state.context.availableTimeslots.find(
                    (ts) => ts[0] === value
                  );

                send({
                  type: 'next',
                  selectedTime: value,
                  selectedTimeLabel: formatAvailableTimeslot(timeslot!, true)
                    .label,
                } as any);
              }}
              options={formatAvailableTimeslots(
                state.context.availableTimeslots
              )}
              size="lg"
            />
          ) : (
            <Alert
              status="error"
              textProps={{
                fontWeight: 'medium',
              }}
            >
              This date is no longer available. Please select a new date.
              Alternatively, please <strong>call 01 704 1979</strong> to speak
              with an Irish Life Financial Services Advisor.
            </Alert>
          )}
        </Box>
      )}
    </Box>
  );
};

const Step1Calendar = () => {
  const { state, send } = useBookAnAppointmentV4();
  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Text fontWeight="medium" fontSize="24px" color="#0C1E68">
        Select a day
      </Text>
      <Calendar
        variantMIL
        availableDates={state.context.availableDates}
        onChange={(date) => {
          send({
            currentDate: date,
            type: 'Change Day',
          } as any);
        }}
      />
    </Box>
  );
};

const formatAvailableTimeslots = (timeslots: [string, string][]) =>
  toSort(timeslots).map((ts) => formatAvailableTimeslot(ts));

const toSort = (timeslots: [string, string][]) => {
  return timeslots.sort((a, b) => {
    return a[0] > b[0] ? 1 : -1;
  });
};

export const formatAvailableTimeslot = (
  [from, to]: [string, string],
  withMinutes?: boolean
) => {
  const [fromH, fromM] = from.split(':');
  const [toH, toM] = to.split(':');
  const label = getLabel({ fromH, fromM, toH, toM }, withMinutes);

  return { label, id: from, value: from };
};

export const getLabel = (
  { fromH = 0, fromM = 0, toH = 0, toM = 0 }: Record<string, number | string>,
  withMinutes?: boolean
) => {
  let from = setHours(new Date(), +fromH);
  from = setMinutes(from, +fromM);
  let to = setHours(new Date(), +toH);
  to = setMinutes(to, +toM);
  return withMinutes
    ? `${format(from, 'h:mm')}-${format(to, 'h:mm a')}`.toLowerCase()
    : `${format(from, 'h')}-${format(to, 'h a')}`;
};

export { Step1 };
