import { Box } from '@chakra-ui/react';
import { HotStepper } from '@irishlifedigitalhub/ds.molecules.hot-stepper';
import React, { useCallback, useEffect, useState } from 'react';
import { useBookAnAppointmentV4 } from '../hooks/useBookAnAppointmentV4';

interface IBoxWhiteRounded {
  enableHotStepper?: boolean;
  className?: string;
}

const BoxWhiteRounded: React.FC<React.PropsWithChildren<IBoxWhiteRounded>> = ({
  enableHotStepper,
  className,
  children,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const { state } = useBookAnAppointmentV4();

  const changeCurrentStep = useCallback(() => {
    switch (state.value) {
      case 'Step 1':
        setCurrentStep(1);
        break;
      case 'Step 2':
        setCurrentStep(2);
        break;
      case 'Step 3':
        setCurrentStep(3);
        break;

      default:
        setCurrentStep(1);
        break;
    }
  }, [state.value]);

  useEffect(() => {
    changeCurrentStep();
  }, [state, changeCurrentStep]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      backgroundColor="white"
      paddingTop="32px"
      paddingBottom="32px"
      paddingLeft="16px"
      paddingRight="16px"
      gap="24px"
      borderRadius="12px"
      className={className}
      boxShadow="4px 0px 24px 0px #5261AC14"
    >
      {enableHotStepper && (
        <Box display="flex" width="100%" flexDirection="column">
          <HotStepper
            currentStep={currentStep}
            steps={[{ title: 'Time' }, { title: 'Details' }, { title: 'Book' }]}
          />
        </Box>
      )}

      {children}
    </Box>
  );
};

export { BoxWhiteRounded };
