import { RouteComponentProps } from '@reach/router';
import {
  TemplateBookAnAppointment,
  TemplateBookAnAppointmentProps,
} from 'common/components/templates/TemplateBookAnAppointment';
import { getLayoutData } from 'common/utils/getLayoutData';
import React from 'react';

export interface TemplateBookAnAppointmentPageProps
  extends RouteComponentProps {
  pageContext: any;
}

export const TemplateBookAnAppointmentPage: React.FC<TemplateBookAnAppointmentPageProps> =
  ({ pageContext }: any) => {
    return (
      <TemplateBookAnAppointment
        {...getBookAnAppointmentPageData(pageContext)}
      />
    );
  };

export default TemplateBookAnAppointmentPage;

export const getBookAnAppointmentPageData = (
  data: any
): TemplateBookAnAppointmentProps => {
  return {
    codename: data.codename,
    layout: getLayoutData(data),
    book_an_appointment: data.book_an_appointment[0],
  };
};
