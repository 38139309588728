import { Box } from '@chakra-ui/react';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import React from 'react';

interface ICountCircle {
  number: number;
}

const CountCircle = ({ number }: ICountCircle) => {
  return (
    <Box
      display="flex"
      width="24px"
      minW="24px"
      height="24px"
      minH="24px"
      borderRadius="24px"
      backgroundColor="#F1F3FD"
      justifyContent="center"
      alignContent="center"
    >
      <Text fontWeight="bold" fontSize="16px" color="#5261AC">
        {number}
      </Text>
    </Box>
  );
};

export { CountCircle };
