import { Box } from '@chakra-ui/react';
import React from 'react';
import { ReactComponent as PrivacyIcon } from '../images/privacy-icon.svg';
import styled from 'styled-components';
import { useBookAnAppointmentV4 } from '../hooks/useBookAnAppointmentV4';
import { RichText } from 'common/components/molecules/RichText';

const CustomRichText = styled(RichText)`
  p {
    color: #4e4670;
  }
  a {
    color: #424fcf;
    text-decoration: underline;
  }
`;

const PrivacyNotice = () => {
  const { state } = useBookAnAppointmentV4();
  return (
    <Box display="flex" gap="8px">
      <Box display="flex" width="32px" height="32px">
        <PrivacyIcon />
      </Box>
      <CustomRichText label={state.context.cmsData.privacy_notice_text} html />
    </Box>
  );
};

export { PrivacyNotice };
