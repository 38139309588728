import { assign, createMachine } from 'xstate';
import { getAvailableTimeslots } from '../AdvisorBooking/config/getAvailableTimeslots';
import { getTeamFromUrl } from '../AdvisorBooking/utils/getTeamFromUrl';
import { onSubmit } from '../AdvisorBooking/config/onSubmit';
import { getSubjectFromPath } from '../AdvisorBooking/utils/getSubjectFromPath';
import { getServiceNameFromUrl } from '../AdvisorBooking/utils/getServiceNameFromUrl';
import { fireAnalyticsEvent } from 'common/utils/fireAnalyticsEvent';
import { IBookAnAppointmentV4 } from './IBookAnAppointmentV4.interface';
export const BookAnAppointmentV4Machine = createMachine(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QCED2qB2BBbAHXqAlhgC4C2YpAagCwB0AyiWLgAQCMAxBJmHcQDdUAaz5pMOLPiKkK1ekxYcEg1AGMAhiUKYA2gAYAugcOJQBWIW2YzIAB6IAtACZ2zugGZnAThrP9ABwB3gCszkEhADQgAJ6I7GF03s4AbM40Id4eQQH6NDQAvgXR4th4BMTklCS0jMxsXGAATk2oTXS4ADZaAGZtZHSlktKVcjUK9cqqmtYYJia2FlY6GLYOCM7OHnT+AR4Je+z63uwB0XEIGfQ0Kfr6KQDsASG53g++RSXoZVIVstW1RQNTgYMB2EgLJAgJazNaIDweFJJAIpbwBGgeJ40NEec7xfZ0dgeEI0e7sB4PNwPFIeT4gIblGRVeR1JRcADyuEorAAwhpOpQIBompDzKhLLCoetHA86M9Hrd2KTiXsaA88Zd2N4dmjbs4HsSUgF2ES6QzfkyxoDJuxWI5efzBcLWJzKJBODyABYaDAwVgAEQ0MVF0PFyxsUsQNICdBCaTV3hOQXJKQ1Pn0dH02QSSsTJsyZu+wz+zPGrIadtYWB6zCaAa0YF53t97p4oP4GCEokGRcZowBEzZlertfrzCbPpgEBUnfUWhW8yMizDktA6yV7gNHn07xNeUppw1LxjNzuj2erwChYkff+LKBHGHNeaY8bXsn7uarXaXV6-R7N4Wv2942k+o6BuO74ttO0zznoRghjCKxwggG50FuO4nEcaommcsSIA8mx0Ke9xPC8xxXsU9K9kBd5lg+tr2iOL4QW+zZTiCYIQkuUJIRGa7xH46EIphe44Ye+EIB4GJJLcpEXsc7yFFR5ojHR1pDkxz51qxE7QZwroYA6AoYEKIo8WKErIZGqFCRhu7YQeeEXD4ISyWeZGvEp14-GppYaWwziceCiErtZAkIGk7iZMEaoZPcKREhq5LuNkIQeN4+haqSNwPD5xaWgO5asEFABGGhqMIoVWfx9iIDQRw7OwiV3ES+T6CE6qScanibJlhE0HsBo7vlt7+YObAeJw5WVdV4arDZDxqrGaK+EtIQJUl3XbAEfVhCcGKnIRRRURgqAQHAtiqSWVo0MuNULRFLi3L1vi7MEYRBBqjghBtdApJklJYk8bjsKNtHjcV7D3fNKGOES7ByiSpwNUamW7d9NzbAkbx3Ma9y7XlKk0X5t1Q5WfImWZLpcqCEAw6udWoRSSQbWEbUbYR3gahliPeHJ57kW8HzE4BpNFQxYEsQ2elTgz4VMy4bivX4gQfeEGOSWESIkYLXneOD4sgUozjy7V6zYu4oQPFlRpEnJNDfc4ISI8DiUNTucXOIbN0S5MHhm49TPom5KaJll-i-fcyXhE1YSZJldz6t7J1AA */
    context: {
      cmsData: {} as IBookAnAppointmentV4,
      currentDate: new Date(),
      availableDates: [] as Date[],
      availableTimeslots: [],
      availableAppointments: [],
      selectedTime: '',
      selectedTimeLabel: '',
      pathname: '',
      loading: false,
      formData: {
        HasPartner: 'individual',
        Name: '',
        EmailAddress: '',
        MobilePhone: '',
        DateOfBirth: '',
        Name2ndLife: '',
        EmailAddress2ndLife: '',
        MobilePhone2ndLife: '',
        DateOfBirth2ndLife: '',
      },
      submittedSuccessfully: false,
      response: {},
    },
    id: 'BookAnAppointmentV4',
    initial: 'Step 1',
    states: {
      'Step 1': {
        entry: ['entryStep1', 'getAvailableDates', 'setLoadingTrue'],
        invoke: {
          src: 'getAvailableTimeslots',
          onDone: [
            {
              actions: ['setAvailableTimeslots', 'setLoadingFalse'],
            },
          ],
          onError: [
            {
              actions: ['setAvailableTimeslots', 'setLoadingFalse'],
            },
          ],
        },
        on: {
          next: 'Step 2',
          'Open Calendar': 'Step 1 - Calendar Opened',
        },
      },
      'Step 1 - Calendar Opened': {
        entry: 'entryStep1CalendarOpened',
        on: {
          'Change Day': {
            actions: 'changeCurrentDate',
            target: 'Step 1 - After Date Changed',
          },
        },
      },
      'Step 1 - After Date Changed': {
        entry: ['entryStep1AfterDateChanged', 'setLoadingTrue'],
        invoke: {
          src: 'getAvailableTimeslots',
          onDone: [
            {
              actions: ['setAvailableTimeslots', 'setLoadingFalse'],
            },
          ],
          onError: [
            {
              actions: ['setAvailableTimeslots', 'setLoadingFalse'],
            },
          ],
        },
        on: {
          next: 'Step 2',
          'Open Calendar': 'Step 1 - Calendar Opened',
        },
      },
      'Step 2': {
        entry: ['entryStep2', 'setSelectedTime'],
        on: {
          next: {
            actions: ['setFormData'],
            target: 'Step 3',
          },

          back: 'Step 1',
        },
      },

      'Step 3': {
        entry: ['entryStep3', 'setLoadingTrue'],
        invoke: {
          src: 'onSubmitForm',
          onDone: [
            {
              actions: ['setLoadingFalse', 'handleInformationsAfterSubmit'],
            },
          ],
          onError: [
            {
              actions: ['setLoadingFalse', 'handleInformationsAfterSubmit'],
            },
          ],
        },
        on: {
          back: 'Step 2',
        },
      },
    },
  },
  {
    actions: {
      getAvailableDates: assign((context, event) => {
        const currentDay = new Date(new Date().setHours(0, 0, 0));
        const startRange = new Date(
          new Date(currentDay).setDate(new Date(currentDay).getDate() + 1)
        );
        const endRange = new Date(
          new Date(startRange).setMonth(new Date(startRange).getMonth() + 1)
        );

        let rangeDates: any[] = [];
        let currentDateLoop = startRange;

        while (currentDateLoop <= endRange) {
          //Avoid weekend
          if (currentDateLoop.getDay() % 6) {
            rangeDates.push(new Date(currentDateLoop));
          }

          currentDateLoop.setDate(new Date(currentDateLoop).getDate() + 1);
        }
        return {
          currentDate: rangeDates[0],
          availableDates: rangeDates,
        };
      }),
      setAvailableTimeslots: assign((_, event) => {
        const newEvent: any = event;
        return {
          availableTimeslots: newEvent.data.timeslots,
          availableAppointments: newEvent.data.availableAppointments,
        };
      }),
      changeCurrentDate: assign((_, event) => {
        fireAnalyticsEvent({
          name: 'IL_book_appointment_v4',
          properties: {
            step: 'step_1',
            info: 'Changed date',
          },
        });
        const newEvent: any = event;
        return {
          availableTimeslots: [],
          availableAppointments: [],
          currentDate: newEvent.currentDate,
        };
      }),
      entryStep1: () => {
        window.scrollTo(0, 0);
        fireAnalyticsEvent({
          name: 'IL_book_appointment_v4',
          properties: {
            step: 'step_1',
            info: 'Entered step 1',
          },
        });
      },
      entryStep1AfterDateChanged: () => {
        window.scrollTo(0, 0);
      },
      entryStep1CalendarOpened: () => {
        window.scrollTo(0, 0);
      },
      entryStep2: () => {
        window.scrollTo(0, 0);
        fireAnalyticsEvent({
          name: 'IL_book_appointment_v4',
          properties: {
            step: 'step_2',
            info: 'Entered step 2',
          },
        });
      },
      entryStep3: () => {
        window.scrollTo(0, 0);
        fireAnalyticsEvent({
          name: 'IL_book_appointment_v4',
          properties: {
            step: 'step_3',
            info: 'Entered step 3',
          },
        });
      },
      setLoadingTrue: assign((_, __) => {
        return {
          loading: true,
        };
      }),
      setLoadingFalse: assign((_, __) => {
        return {
          loading: false,
        };
      }),
      setSelectedTime: assign((_, event) => {
        const newEvent: any = event;
        return {
          selectedTime: newEvent.selectedTime,
          selectedTimeLabel: newEvent.selectedTimeLabel,
        };
      }),
      setFormData: assign((_, event) => {
        let formData: any = { ...event };
        delete formData.type;
        return {
          formData: formData,
        };
      }),
      handleInformationsAfterSubmit: assign((_, event) => {
        const newEvent: any = event;
        return {
          submittedSuccessfully: !newEvent.data.message,
          response: newEvent.data.message ? undefined : newEvent.data,
        };
      }),
    },
  }
).withConfig({
  services: {
    getAvailableTimeslots: async (context) => {
      try {
        const { timeslots, availableAppointments } =
          await getAvailableTimeslots(context.currentDate, {
            teamName: getTeamFromUrl(context.pathname),
          });
        return { timeslots, availableAppointments };
      } catch (e) {
        return { timeslots: [], availableAppointments: [] };
      }
    },
    onSubmitForm: async (context) => {
      const formattedFormData = formatFormData(context);
      const availableAppointments = context.availableAppointments;
      const pathname = context.pathname;
      const preScreen = undefined;
      const customCrmData = {
        campaignId: context.cmsData.campaign_id,
        sourceId: '',
        subsourceId: '',
      };

      return onSubmit(
        formattedFormData,
        availableAppointments,
        pathname,
        preScreen,
        {
          subject: getSubjectFromPath(pathname, formattedFormData),
          TeamName: getTeamFromUrl(pathname),
          servicename: getServiceNameFromUrl(pathname),
        },
        customCrmData
      );
    },
  },
});

const formatFormData = (context: any) => {
  const dateOfBirthFormatted = formatDate(context.formData.DateOfBirth);

  const individualData = {
    appointmentDate: context.currentDate
      .toLocaleDateString(undefined, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      .replace('/', '-')
      .replace('/', '-'),
    appointmentTime: {
      label: context.selectedTimeLabel,
      value: context.selectedTime,
    },
    dateOfBirth: dateOfBirthFormatted,
    email: context.formData.EmailAddress,
    firstName:
      context.formData.Name.substring(0, context.formData.Name.indexOf(' ')) ||
      ' ',
    lastName: context.formData.Name.substring(
      context.formData.Name.indexOf(' ') + 1
    ),
    hasPartner: context.formData.HasPartner !== 'individual',
    phoneNumber: context.formData.MobilePhone,
  };

  let partnerData = {};

  if (context.formData.HasPartner === 'with_partner') {
    const dateOfBirthPartnerFormatted = formatDate(
      context.formData.DateOfBirth2ndLife
    );

    partnerData = {
      partnerDateOfBirth: dateOfBirthPartnerFormatted,
      partnerEmail: context.formData.EmailAddress2ndLife,
      partnerFirstName:
        context.formData.Name2ndLife.substring(
          0,
          context.formData.Name2ndLife.indexOf(' ')
        ) || ' ',
      partnerLastName: context.formData.Name2ndLife.substring(
        context.formData.Name2ndLife.indexOf(' ') + 1
      ),
      partnerPhoneNumber: context.formData.MobilePhone2ndLife,
    };
  }

  const formData = { ...individualData, ...partnerData };

  return formData;
};

const formatDate = (date: any) => {
  const dateParts = date.split('/');
  return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
};
