import { Box } from '@chakra-ui/react';
import { Card } from '@irishlifedigitalhub/ds.atoms.card';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import { Button } from '@irishlifedigitalhub/ds.atoms.button';
import React from 'react';
import { navigate } from '@reach/router';

const NeedMoreInformation = () => {
  return (
    <Card
      display="flex"
      flexDirection={['column', 'column', 'column', 'row']}
      gap="18px"
      padding={['24px', '24px', '24px', '24px']}
    >
      <Box display="flex" flexDirection="column" gap="2px">
        <Text fontWeight="semibold" fontSize="20px" color="#0C1E68">
          Need more information?
        </Text>
        <Text fontWeight="medium" fontSize="16px" color="#4E4670">
          See how a financial advisor can help you.
        </Text>
      </Box>
      <Box
        display="flex"
        justifyContent={['start', 'start', 'start', 'center']}
        alignItems={['start', 'start', 'start', 'center']}
        flex={'1'}
      >
        <Button
          variant="secondary"
          size="sm"
          onClick={() => navigate('/get-advice')}
        >
          Learn more
        </Button>
      </Box>
    </Card>
  );
};

export { NeedMoreInformation };
