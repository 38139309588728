import React from 'react';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import { Button } from '@irishlifedigitalhub/ds.atoms.button';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';

interface IModalBookAppointmentV4 {
  title: string;
  text: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  finalRef: React.MutableRefObject<null>;
}

const ModalBookAppointmentV4 = ({
  isOpen,
  onOpen,
  onClose,
  finalRef,
  title,
  text,
}: IModalBookAppointmentV4) => {
  return (
    <Modal
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent p={'16px'} style={{ margin: '16px' }}>
        <ModalCloseButton
          backgroundColor="#F1F3FD"
          color="#5261AC"
          borderRadius="40px"
        />
        <ModalBody display="flex" flexDirection="column" gap="24px">
          <Box display="flex" flexDirection="column" gap="6px">
            <Text variant="title-sm" color="#0C1E68">
              {title}
            </Text>
            <Text variant="body-md" color="#4E4670">
              {text}
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            size="sm"
            backgroundColor="#5E6CDA"
            color="white"
            width="100%"
            fontWeight="600"
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { ModalBookAppointmentV4 };
