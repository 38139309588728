import { Button } from '@irishlife/ilgroupdesignsystem.atoms.button'
import { modifySteps } from 'common/components/organisms/Calculator/utils/modifySteps'
import { config } from 'common/components/organisms/Calculator/config/index'
import {
    CalculatorStep,
    CalculatorStepProps,
} from 'common/components/organisms/Calculator/components/steps/CalculatorStep'
import { useForm } from 'react-hook-form'
import {
    CalculatorMachineEvent,
    InvestmentFormType,
    PensionFormType,
} from 'common/components/organisms/Calculator/utils/interfaces'

import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import React from 'react'

export interface AdjustmentFormProps {}

export const AdjustmentForm: React.FC<AdjustmentFormProps> = () => {
    const { send, state } = useCalculatorMachine()
    const calculatorSteps = modifySteps(state.context.initialQuestions)
    const { stepsTransformation } = config[state.context.calc_type]
    let transformedSteps = stepsTransformation(calculatorSteps)
    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm({
        shouldUnregister: false,
        mode: 'onSubmit',
    })
    const questions = transformedSteps?.[0].steps
    const formValues = watch()
    const onSubmit = (updatedValues: any) => {
        let user = {}
        if (state.context.formValues) {
            user = {
                ...state?.context?.formValues,
                initialAmount:
                    updatedValues.initialAmount ||
                    (state.context.formValues as InvestmentFormType)
                        .initialAmount,
                monthlyAmount:
                    updatedValues.monthlyAmount ||
                    (state.context.formValues as InvestmentFormType)
                        .monthlyAmount,
                noOfYears:
                    updatedValues.noOfYears ||
                    (state.context.formValues as InvestmentFormType).noOfYears,
            }
        }
        send({
            type: 'Get Adjusted Quote',
            payload: { user },
        } as CalculatorMachineEvent)
    }
    return (
        <>
            <form
                onSubmit={handleSubmit(onSubmit, (errors) => {
                    onSubmit(formValues)
                })}
            >
                {questions.map((calculatorStep: CalculatorStepProps) => {
                    const defaultValue =
                        (state.context.formValues as PensionFormType | InvestmentFormType)[
                            calculatorStep.fieldName as keyof (
                                | PensionFormType
                                | InvestmentFormType
                            )
                        ]
                    return (
                        <CalculatorStep
                            control={control}
                            key={calculatorStep?.fieldName}
                            props={calculatorStep}
                            register={register}
                            errors={errors}
                            defaultValue={defaultValue}
                        />
                    )
                })}

                <Button type='submit' variant='secondary'>
                    Update
                </Button>
            </form>
        </>
    )
}
