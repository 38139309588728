import {
  CalendarIcon,
  GoogleIcon,
  Office365Icon,
  OutlookIcon,
  YahooIcon,
} from '@irishlifedigitalhub/ds.icons';
import {
  CalendarEvent,
  google,
  ics,
  office365,
  outlook,
  yahoo,
} from 'calendar-link';
import React from 'react';

export const getCalendarOptions = (event: CalendarEvent) => [
  {
    id: 'google',
    icon: <GoogleIcon />,
    label: 'Google',
    url: google(event),
  },
  {
    id: 'outlook',
    icon: <OutlookIcon />,
    label: 'Outlook',
    url: outlook(event),
  },
  {
    id: 'office365',
    icon: <Office365Icon />,
    label: 'Office 365',
    url: office365(event),
  },
  {
    id: 'yahoo',
    icon: <YahooIcon />,
    label: 'Yahoo',
    url: yahoo(event),
  },
  {
    id: 'other',
    icon: <CalendarIcon />,
    label: 'Other',
    url: ics(event),
  },
];
