import { AppointeddWidget } from 'common/components/organisms/AppointeddWidget';
import { BookAnAppointmentV4 } from 'common/components/organisms/BookAnAppointmentV4';
import { AccordionInfoPanel } from 'common/components/organisms/templateSections/AccordionInfoPanelPage/index';
import { AccordionSection } from 'common/components/organisms/templateSections/AccordionSection';
import { BannerCta } from 'common/components/organisms/templateSections/BannerCta';
import { BasicTable } from 'common/components/organisms/templateSections/BasicTable/BasicTable';
import { BodyOfText } from 'common/components/organisms/templateSections/BodyOfText';
import { BulletsWithImage } from 'common/components/organisms/templateSections/BulletsWithImage';
import { Calculator } from 'common/components/organisms/templateSections/Calculator';
import { Campaign3DSlideshow } from 'common/components/organisms/templateSections/Campaign3DSlideshowPage';
import { CardGrid } from 'common/components/organisms/templateSections/CardGrid';
import { Cards } from 'common/components/organisms/templateSections/Cards';
import { CardsCarousel } from 'common/components/organisms/templateSections/CardsCarousel';
import { CenteredText } from 'common/components/organisms/templateSections/CenteredTextPage';
import { CollegeCostsCalculator } from 'common/components/organisms/templateSections/CollegeCostsCalculator';
import { CountingCardsWithPagination } from 'common/components/organisms/templateSections/CountingCardsWithPaginationPage';
import { Cta } from 'common/components/organisms/templateSections/Cta';
import { DocumentDownloadPanel } from 'common/components/organisms/templateSections/DocumentDownloadPage';
import { Featured } from 'common/components/organisms/templateSections/Featured';
import { Iframe } from 'common/components/organisms/templateSections/Iframe';
import { ImageWithParagraph } from 'common/components/organisms/templateSections/ImageWithParagraphPage';
import { InvestmentCalculatorSection } from 'common/components/organisms/templateSections/InvestmentCalculator';
import { MediaCard } from 'common/components/organisms/templateSections/MediaCardPage';
import { MenuCardGrid } from 'common/components/organisms/templateSections/MenuCardGrid';
import { Metamo } from 'common/components/organisms/templateSections/Metamo';
import { MiniFooter } from 'common/components/organisms/templateSections/MiniFooterPage';
import { MultiCampaignFeature } from 'common/components/organisms/templateSections/MultiCampaignFeaturePage';
import { MultiCardGalleryWithStepper } from 'common/components/organisms/templateSections/MultiCardGalleryWithStepperPage';
import { MultiFeatureList } from 'common/components/organisms/templateSections/MultiFeatureListPage';
import { MultiPaginatedCards } from 'common/components/organisms/templateSections/MultiPaginatedCardsPage';
import { Notices } from 'common/components/organisms/templateSections/Notices';
import { Pillars } from 'common/components/organisms/templateSections/Pillars';
import { ProductListing } from 'common/components/organisms/templateSections/ProductListing';
import { ProofPointsSection } from 'common/components/organisms/templateSections/ProofPointsSection';
import { Resources } from 'common/components/organisms/templateSections/Resources';
import { ResourcesWithLinks } from 'common/components/organisms/templateSections/ResourcesWithLinksPage';
import { SharedCard } from 'common/components/organisms/templateSections/ShardCard';
import { SupportCenterBase } from 'common/components/organisms/templateSections/SupportCenter';
import { Table } from 'common/components/organisms/templateSections/Table';
import { TeamGridFilter } from 'common/components/organisms/templateSections/TeamGridFilterPage';
import { Testimonials } from 'common/components/organisms/templateSections/Testimonials';
import { TextWithImage } from 'common/components/organisms/templateSections/TextWithImage';
import { TextWithTitle } from 'common/components/organisms/templateSections/TextWithTitle';
import { Timeline } from 'common/components/organisms/templateSections/Timeline';
import { TrapeziumBanner } from 'common/components/organisms/templateSections/TrapeziumBannerPage';
import { VideoWithText } from 'common/components/organisms/templateSections/VideoWithText';
import { Warnings } from 'common/components/organisms/templateSections/Warnings';

export const templateSectionMapper = () => {};

export type TemplateSectionType =
  | 'templatesection___accordionsection'
  | 'templatesection___banner_cta'
  | 'templatesection___bodyoftext'
  | 'templatesection___bulletswithimage'
  | 'templatesection___card_grid'
  | 'templatesection___cards'
  | 'templatesection___cardscarousel'
  | 'templatesection___collegecostscalculator'
  | 'templatesection___cta'
  | 'templatesection___featured'
  | 'templatesection___iframe'
  | 'templatesection___investmentcalculatorsection'
  | 'templatesection___metamo'
  | 'templatesection___notices'
  | 'templatesection___productlisting'
  | 'templatesection___proofpoints'
  | 'templatesection___resources'
  | 'templatesection___supportcenter'
  | 'templatesection___table'
  | 'templatesection___testimonials'
  | 'templatesection___textwithimage'
  | 'templatesection___textwithtitle'
  | 'templatesection___timeline'
  | 'templatesection___videowithtext'
  | 'templatesection___warnings'
  | 'templatesection___centeredtext'
  | 'templatesection___resourceswithlinks'
  | 'templatesection___minifooter'
  | 'templatesection___multicampaignfeature'
  | 'templatesection___imagewithparagraph'
  | 'templatesection___multicardgallerywithstepper'
  | 'templatesection___countingcardswithpagination'
  | 'templatesection___documentdownloadpanel'
  | 'templatesection___trapeziumbanner'
  | 'templatesection___shardcard_c08e77b'
  | 'templatesection___campaign3dslideshow'
  | 'templatesection___mediacard'
  | 'templatesection___multifeaturelist'
  | 'templatesection___accordioninfopanel'
  | 'templatesection___teamgridfilter'
  | 'templatesection___multipaginatedcards'
  | 'calculator'
  | 'templatesection___menucardgrid'
  | 'templatesection___basic_table'
  | 'templatesection___appointeddwidget'
  | 'template_section___book_an_appointment_v4';

export const TemplateSectionComponent: { [key: string]: any } = {
  templatesection___accordionsection: AccordionSection,
  templatesection___banner_cta: BannerCta,
  templatesection___bodyoftext: BodyOfText,
  templatesection___bulletswithimage: BulletsWithImage,
  templatesection___card_grid: CardGrid,
  templatesection___cards: Cards,
  templatesection___cardscarousel: CardsCarousel,
  templatesection___collegecostscalculator: CollegeCostsCalculator,
  templatesection___cta: Cta,
  templatesection___featured: Featured,
  templatesection___iframe: Iframe,
  templatesection___investmentcalculatorsection: InvestmentCalculatorSection,
  templatesection___metamo: Metamo,
  templatesection___notices: Notices,
  templatesection___productlisting: ProductListing,
  templatesection___proofpoints: ProofPointsSection,
  templatesection___resources: Resources,
  templatesection___supportcenter: SupportCenterBase,
  templatesection___table: Table,
  templatesection___testimonials: Testimonials,
  templatesection___textwithimage: TextWithImage,
  templatesection___textwithtitle: TextWithTitle,
  templatesection___timeline: Timeline,
  templatesection___videowithtext: VideoWithText,
  templatesection___warnings: Warnings,
  templatesection___centeredtext: CenteredText,
  templatesection___resourceswithlinks: ResourcesWithLinks,
  templatesection___minifooter: MiniFooter,
  templatesection___multicampaignfeature: MultiCampaignFeature,
  templatesection___imagewithparagraph: ImageWithParagraph,
  templatesection___multicardgallerywithstepper: MultiCardGalleryWithStepper,
  templatesection___countingcardswithpagination: CountingCardsWithPagination,
  templatesection___documentdownloadpanel: DocumentDownloadPanel,
  templatesection___trapeziumbanner: TrapeziumBanner,
  templatesection___shardcard_c08e77b: SharedCard,
  templatesection___campaign3dslideshow: Campaign3DSlideshow,
  templatesection___mediacard: MediaCard,
  templatesection___multifeaturelist: MultiFeatureList,
  templatesection___accordioninfopanel: AccordionInfoPanel,
  templatesection___teamgridfilter: TeamGridFilter,
  templatesection___multipaginatedcards: MultiPaginatedCards,
  calculator: Calculator,
  templatesection___menucardgrid: MenuCardGrid,
  templatesection___pillars: Pillars,
  templatesection___basic_table: BasicTable,
  templatesection___appointeddwidget: AppointeddWidget,
  template_section___book_an_appointment_v4: BookAnAppointmentV4,
};
