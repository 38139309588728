/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, FormControl, FormHelperText } from '@chakra-ui/react';
import { FormLabel } from '@irishlifedigitalhub/ds.atoms.form-label';
import React from 'react';
import PhoneInput from 'react-phone-number-input';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';

export const getStyles = ({ isInvalid }: { isInvalid?: boolean }) => ({
  bg: 'brand.50',
  rounded: 'md',
  pl: 3,
  width: 'full',
  borderColor: isInvalid ? 'error' : 'brand.400',
  borderWidth: isInvalid ? '2px' : '1px',
  input: {
    height: 12,
    width: 'full',
    fontSize: 'md',
    bg: 'brand.50',
    fontWeight: '500',
    rounded: 'md',
    pl: 2,
    pr: 4,
    color: 'black',
    _placeholder: {
      color: 'monochrome.400',
    },
    '&:focus, &:active, &:focus-visible': {
      outlineColor: isInvalid ? 'error' : 'brand.400',
      borderColor: isInvalid ? 'error' : 'brand.400',
      boxShadow: 'rgb(49, 130, 206) 0px 0px 0px 1px',
    },
  },
  '.PhoneInput': {
    display: 'flex',
    alignItems: 'center',
    height: 12,
  },
  '.PhoneInputCountry': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: 7,
  },
  '.PhoneInputCountrySelect': {
    position: 'absolute',
    top: '0',
    left: '0',
    height: 'full',
    width: 'full',
    zIndex: '1',
    border: '0',
    opacity: '0',
    cursor: 'pointer',
  },
  '.PhoneInputCountryIcon': {
    rounded: 'sm',
    overflow: 'hidden',
  },
  '.PhoneInputCountryIconImg': {
    height: 4,
  },
  '.PhoneInputCountryIcon--border': {},
});

export function PhoneNumberInput({
  isUncontrolled,
  label,
  isInvalid,
  helperText,
  ...rest
}: any) {
  if (isUncontrolled) {
    return (
      <Box>
        <FormControl>
          <FormLabel
            htmlFor={rest.id}
            style={{ color: 'var(--chakra-colors-monochrome-800)' }}
          >
            {label}
          </FormLabel>
          <Box sx={getStyles({ isInvalid })}>
            {/* @ts-ignore */}
            <PhoneInput
              initialValueFormat="national"
              defaultCountry="IE"
              name={rest.id}
              {...rest}
            />
          </Box>
          {helperText && (
            <FormHelperText
              sx={{ color: isInvalid && 'error' }}
              style={{
                marginTop: 'var(--chakra-space-1)',
                lineHeight: 'var(--chakra-lineHeights-normal)',
                fontSize: '16px',
                fontWeight: 500,
              }}
            >
              {helperText}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    );
  }
  return (
    <Box>
      <FormControl>
        <FormLabel
          htmlFor={rest.id}
          style={{ color: 'var(--chakra-colors-monochrome-800)' }}
        >
          {label}
        </FormLabel>
        <Box sx={getStyles({ isInvalid })}>
          {/* @ts-ignore */}
          <PhoneInputWithCountry
            initialValueFormat="national"
            defaultCountry="IE"
            name={rest.id}
            {...rest}
          />
        </Box>
        {helperText && (
          <FormHelperText
            sx={{ color: isInvalid && 'error' }}
            style={{
              marginTop: 'var(--chakra-space-1)',
              lineHeight: 'var(--chakra-lineHeights-normal)',
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
}
