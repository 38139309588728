import { Box, Divider, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { CalendarIcon, OnlineMeetingIcon } from '@irishlifedigitalhub/ds.icons';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import { TitleAndTextWithIcon } from '../../../TitleAndTextWithIcon';
import { BoxWhiteRounded } from '../../../BoxWhiteRounded';
import { useBookAnAppointmentV4 } from 'common/components/organisms/BookAnAppointmentV4/hooks/useBookAnAppointmentV4';
import { Form } from './Form';
import { ModalBookAppointmentV4 } from '../../../ModalBookAppointmentV4';

const Step2 = () => {
  const { state } = useBookAnAppointmentV4();
  const {
    isOpen: isOpenOnlineMeetingModal,
    onOpen: onOpenOnlineMeetingModal,
    onClose: onCloseOnlineMeetingModal,
  } = useDisclosure();

  const finalRefOpenOnlineMeetingModal = React.useRef(null);

  return (
    <BoxWhiteRounded className="step-2" enableHotStepper>
      <form
        style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}
        className="details-section"
      >
        {/* Booking Summary */}
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'12px'}
          className="booking-summary"
        >
          <TitleAndTextWithIcon
            text={`${state.context.currentDate.toLocaleDateString(undefined, {
              weekday: 'long',
            })}, ${state.context.currentDate.toLocaleDateString(undefined, {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
            })} ${state.context.selectedTimeLabel}`}
            Icon={CalendarIcon}
          />
          <TitleAndTextWithIcon
            text={
              <Box display={'flex'}>
                <Text color={'#4E4670'} fontSize={'16px'} fontWeight={'medium'}>
                  Online meeting
                </Text>
                <Text
                  color={'#424FCF'}
                  fontSize={'16px'}
                  fontWeight={'medium'}
                  cursor={'pointer'}
                  textDecoration={'underline'}
                  marginLeft={'3px'}
                  onClick={() => onOpenOnlineMeetingModal()}
                >
                  More info
                </Text>
                <ModalBookAppointmentV4
                  title={
                    state.context.cmsData.step_2__online_meeting_modal___title
                  }
                  text={
                    state.context.cmsData.step_2__online_meeting_modal___text
                  }
                  isOpen={isOpenOnlineMeetingModal}
                  finalRef={finalRefOpenOnlineMeetingModal}
                  onClose={onCloseOnlineMeetingModal}
                  onOpen={onOpenOnlineMeetingModal}
                />
              </Box>
            }
            Icon={OnlineMeetingIcon}
          />
          <Divider
            orientation="horizontal"
            borderColor="#D8D6DF"
            borderWidth="1.5px"
          />
        </Box>
        <Form />
      </form>
    </BoxWhiteRounded>
  );
};

export { Step2 };
