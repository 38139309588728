import { useCalculatorMachine } from 'common/components/organisms/Calculator/utils/context'
import { debounce } from 'lodash'
import { useCallback, useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'
import {
    CalculatorMachineEvent,
    PensionFormType,
} from 'common/components/organisms/Calculator/utils/interfaces'

export const pensionCalculatorState = atom({
    key: 'pensionCalculatorState',
    default: {
        userContribution: 0,
        retirementAge: 0,
        projection: 0,
        statePension: 0,
        salary: 0,
    },
})

export function usePensionCalculator() {
    const [calcState, setCalcState] = useRecoilState(pensionCalculatorState)
    const { state, send } = useCalculatorMachine()

    useEffect(() => {
        if (state.context?.formValues) {
            const userContribution = Number(
                (state.context.formValues as PensionFormType).contribution1 ||
                    (state.context.formValues as PensionFormType)
                        .contribution2 ||
                    0
            )

            setCalcState({
                userContribution,
                retirementAge: (state.context.formValues as PensionFormType).retirementAge || 66,
                projection:
                    (state.context.quoteData as any)?.barChart[0]
                        .userContributedPensionProjection || 0,
                statePension:
                    (state.context.quoteData as any)?.barChart[0]
                        .statePensionValue || 0,
                salary: Number(
                    (state.context.formValues as PensionFormType).salary
                ),
            })
        }
    }, [state.context, setCalcState])

    const debouncedUpdate = useCallback(
        debounce((userContribution, retirementAge) => {
            let user = {}
            if ((state.context.formValues as PensionFormType)?.contribution2) {
                user = {
                    ...state?.context?.formValues,
                    contribution2: userContribution,
                    retirementAge: retirementAge,
                    isStatePension:
                        retirementAge >= 66 &&
                        (state.context.formValues as PensionFormType)
                            ?.isStatePension === 'Y'
                            ? 'Y'
                            : 'N',
                }
            } else {
                user = {
                    ...state?.context?.formValues,
                    contribution1: userContribution,
                    retirementAge: retirementAge,
                    isStatePension:
                        retirementAge >= 66 &&
                        (state.context.formValues as PensionFormType)
                            ?.isStatePension === 'Y'
                            ? 'Y'
                            : 'N',
                }
            }
            send({
                type: 'Get Adjusted Quote',
                payload: { user },
            } as CalculatorMachineEvent)
        }, 1500),
        []
    )

    const changeUserContribution = useCallback(
        (userContribution, retirementAge) => {
            debouncedUpdate(userContribution, retirementAge)
        },
        [debouncedUpdate]
    )

    const setContribution = useCallback(
        (contribution) => {
            setCalcState((oldState) => ({
                ...oldState,
                userContribution: contribution,
            }))
            changeUserContribution(contribution, calcState.retirementAge)
        },
        [setCalcState, calcState.retirementAge, changeUserContribution]
    )

    const setRetirementAge = useCallback(
        (age) => {
            setCalcState((oldState) => ({ ...oldState, retirementAge: age }))
            changeUserContribution(calcState.userContribution, age)
        },
        [setCalcState, calcState.userContribution, changeUserContribution]
    )

    return {
        state: calcState,
        actions: {
            setContribution,
            setRetirementAge,
        },
    }
}
