import React from 'react';
import { Layout, LayoutProps } from 'common/components/organisms/Layout';
import { BookAnAppointmentV4 } from '../organisms/BookAnAppointmentV4/BookAnAppointmentV4';
import { IBookAnAppointmentV4 } from '../organisms/BookAnAppointmentV4/IBookAnAppointmentV4.interface';

export interface TemplateBookAnAppointmentProps {
  codename?: string;
  layout: LayoutProps;
  book_an_appointment: IBookAnAppointmentV4;
}

export const TemplateBookAnAppointment: React.FC<TemplateBookAnAppointmentProps> =
  ({ codename, layout, book_an_appointment }) => {
    const newLayout = {
      ...layout,
      navigation: layout.customNavigationMenu || layout.navigation,
    };

    return (
      <Layout {...newLayout} noBreadcrumbs>
        <BookAnAppointmentV4 {...book_an_appointment} />
      </Layout>
    );
  };
