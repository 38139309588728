import { Box } from '@chakra-ui/react';
import { Card } from '@irishlifedigitalhub/ds.atoms.card';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import React from 'react';
import { CustomLink } from './CustomLink';

const PleaseNote = () => {
  return (
    <Card
      display={'flex'}
      flexDirection={['column', 'column', 'column', 'column']}
      padding={['24px', '24px', '24px', '24px']}
      gap="8px"
    >
      <Box display="flex" flexDirection="column">
        <Text fontWeight="semibold" fontSize="20px" color="#0C1E68">
          Please note:
        </Text>
      </Box>
      <CustomLink
        text="Click here to talk about Irish Life Health"
        url={'https://www.irishlifehealth.ie/contact-us'}
        target="_blank"
      />
      <CustomLink
        text="Click here to talk about company pensions"
        url={'https://www.irishlifeemployersolutions.ie/contact-us'}
        target="_blank"
      />
    </Card>
  );
};

export { PleaseNote };
