import { Box } from '@chakra-ui/react';
import { Card } from '@irishlifedigitalhub/ds.atoms.card';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import { Button } from '@irishlifedigitalhub/ds.atoms.button';
import { PhoneIcon } from '@irishlifedigitalhub/ds.icons';
import React from 'react';

const CantFindSuitableTime = () => {
  return (
    <Card
      display={'flex'}
      flexDirection={['column', 'column', 'column', 'column']}
      gap="18px"
      padding={['24px', '24px', '24px', '24px']}
    >
      <Box display="flex" flexDirection="column" gap="2px">
        <Text fontWeight="semibold" fontSize="20px" color="#0C1E68">
          Can’t find a suitable time?
        </Text>
        <Text fontWeight="medium" fontSize="16px" color="#4E4670">
          Talk to an advisor today by calling the number below.
        </Text>
      </Box>
      <Box display="flex">
        <Button
          variant="secondary"
          size="md"
          gap="10px"
          onClick={() => (window.location.href = 'tel:+353017041979')}
        >
          <PhoneIcon /> Call us: (0)1 704 1979
        </Button>
      </Box>
    </Card>
  );
};

export { CantFindSuitableTime };
