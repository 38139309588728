import { Box } from '@chakra-ui/react';
import React from 'react';
import { getCalendarOptions } from './getCalendarOptions';
import { CalendarEvent } from 'calendar-link';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import { Button } from '@irishlifedigitalhub/ds.atoms.button';
import styled from 'styled-components';

interface IAddToMyCalendar {
  event: CalendarEvent;
}

const StyledButton = styled(Button)`
  border-radius: 0 !important;
`;

const AddToMyCalendar = ({ event }: IAddToMyCalendar) => {
  return (
    <Box
      display={'flex'}
      flexDirection="column"
      borderRadius={'8px'}
      boxShadow={'0px 0px 20px 0px #5261AC3D'}
      gap={'6px'}
      paddingTop={'10px'}
      paddingBottom={'10px'}
    >
      {getCalendarOptions(event)?.map((option) => (
        <StyledButton
          variant="ghost"
          onClick={() => window.open(option.url, '_blank')}
          key={option.id}
          _hover={{ bg: 'vibrant.100', color: 'white' }}
          _focus={{ bg: 'vibrant.100', color: 'white' }}
          display={'flex'}
          justifyContent={'start'}
          gap={'10px'}
        >
          {option.icon}
          <Text fontWeight="semibold" color="monochrome.900">
            {option.label}
          </Text>
        </StyledButton>
      ))}
    </Box>
  );
};

export { AddToMyCalendar };
