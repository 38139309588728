import React from 'react'
import { Box, Divider, Stack, AbsoluteCenter } from '@chakra-ui/react'
import { Result } from './Result'
import { Text } from '@irishlifedigitalhub/ds.atoms.text'
import { LineChart } from './Chart'
import { INVESTMENT_RATE } from '../utils/getCalculatorReturns'
export interface ResultsProps {
    results: any
    term: number
}
export const Results: React.FC<ResultsProps> = ({ results, term }) => {
    return (
        <Box mt={'24px'}>
            <Stack align={'center'}>
                <Result
                    subtitle={`After ${term} years without interest`}
                    heading='Amount of money contributed'
                    highlighted
                    note=''
                    value={results.returnOnCash}
                />
                <Box position='relative' padding='10'>
                    <Divider />
                    <AbsoluteCenter bg='white' px='4'>
                        <Text
                            color='monochrome.800'
                            variant='title-sm'
                            pt={6}
                            align='center'
                        >
                            VS
                        </Text>
                    </AbsoluteCenter>
                </Box>
                <Result
                    subtitle={`Estimated value after ${term} years with interest`}
                    heading={`Value after ${term} years`}
                    highlighted
                    note={`(Based on ${INVESTMENT_RATE}% compounded annually)`}
                    value={results.returnOnInvestment}
                />
            </Stack>
            <Divider />
            <Stack>
                <Box my={'54px'} width='100%'>
                    <LineChart
                        cashDataPoints={results.cashDataPoints}
                        investmentDataPoints={results.investmentDataPoints}
                    />
                </Box>
            </Stack>
        </Box>
    )
}
