import React from 'react';
import { Step1 } from './Steps/Step1/Step1';
import { Step2 } from './Steps/Step2/Step2';
import { Step3 } from './Steps/Step3/Step3';
import { useBookAnAppointmentV4 } from '../../hooks/useBookAnAppointmentV4';

const Appointment = () => {
  const { state } = useBookAnAppointmentV4();

  return (
    <>
      {(state.value as string).includes('Step 1') && <Step1 />}
      {(state.value as string).includes('Step 2') && <Step2 />}
      {(state.value as string).includes('Step 3') && <Step3 />}
    </>
  );
};

export { Appointment };
