const applyDateMask = (value: string) => {
    const onlyNumbers = value.replace(/\D/g, '');

    if (onlyNumbers.length <= 2) return onlyNumbers;
    if (onlyNumbers.length <= 4)
      return `${onlyNumbers.slice(0, 2)}/${onlyNumbers.slice(2)}`;
    return `${onlyNumbers.slice(0, 2)}/${onlyNumbers.slice(
      2,
      4
    )}/${onlyNumbers.slice(4, 8)}`;
  };

  export { applyDateMask }