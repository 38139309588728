import React from 'react';
import { Link } from '@irishlifedigitalhub/ds.atoms.link';
import { ReactComponent as RightArrowIcon } from '../images/right-arrow.svg';

interface ICustomLink {
  text: string;
  url: string;
  enableIcon?: boolean;
  underline?: boolean;
  target?: string;
}

const CustomLink = ({
  text,
  url,
  enableIcon = true,
  target,
  underline = true,
}: ICustomLink) => {
  return (
    <Link
      href={url}
      color="#424FCF"
      textDecoration={underline ? 'underline' : ''}
      display="flex"
      alignItems="center"
      gap="6px"
      target={target}
    >
      {text}
      {enableIcon && <RightArrowIcon />}
    </Link>
  );
};

export { CustomLink };
