import { useInterpret } from '@xstate/react';
import React, { createContext, useMemo } from 'react';
import { BookAnAppointmentV4Machine } from '../BookAnAppointmentV4Machine';
import { InterpreterFrom } from 'xstate';
import { useLocation } from '@reach/router';
import { IBookAnAppointmentV4 } from '../IBookAnAppointmentV4.interface';

interface IBookAnAppointmentV4Provider {
  cmsData: IBookAnAppointmentV4;
}

const BookAnAppointmentV4Context = createContext({
  BookAnAppointmentV4Service: {} as InterpreterFrom<
    typeof BookAnAppointmentV4Machine
  >,
});

export const BookAnAppointmentV4Provider: React.FC<
  React.PropsWithChildren<IBookAnAppointmentV4Provider>
> = (props) => {
  const { pathname } = useLocation();

  const BookAnAppointmentV4Service = useInterpret(BookAnAppointmentV4Machine, {
    context: {
      cmsData: props.cmsData,
      pathname: pathname.replace('/preview', ''),
    },
  });

  const service = useMemo(
    () => ({ BookAnAppointmentV4Service }),
    [BookAnAppointmentV4Service]
  ); // value is cached by useMemo

  return (
    <BookAnAppointmentV4Context.Provider value={service}>
      {props.children}
    </BookAnAppointmentV4Context.Provider>
  );
};

export default BookAnAppointmentV4Context;
